#typing_notifications {
    display: none;
    margin-left: 10px;
    font-style: italic;
    color: hsl(0deg 0% 53%);
}

#typing_notification_list {
    list-style: none;
    margin: 0;
}

#mark_as_read_turned_off_banner {
    /* override the margin of main-view-banner since it causes
       more gap than we want between mark as read banner and typing
       notification.  */
    margin: 5px 0;
}
